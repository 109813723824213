import { create } from "zustand";

interface ImageStore {
	imageLookup: Record<
		string,
		{
			didLoad: boolean;
		}
	>;
}
/**
 * Only used on web for now
 */
const useImageStore = create<ImageStore>((set) => ({
	imageLookup: {},
}));

export default useImageStore;
