import { curEnv, isDev, notProd } from "src/config";

export const CONTACT_SYNC = false;

export const MODAL_PRELOADING = false;

export const GRATITUDE = false;

// Stable flags (clean up eventually)
export const DEDUPLICATE_PHOTOS_OPTION = true;
export const PROMPT_SHARE_AFTER_CREATE = true;
export const SHARE_PREVIEW = true;
export const GATHERING_QR_CODE = true;
export const SEND_ONLINE_UPDATES = true;
export const COMMUNITY_GATHERING_PINNING = true;
export const PUBLIC_API_ENABLED = true;
export const INLINE_CREATE_ALBUM = true;
export const TOP_PHOTOS = true;
export const BACKGROUND_UPLOAD = true;
export const ANIMATED_OPEN_GRAPH = true;
export const ALLOW_ATTACHMENT_DOWNLOAD = true;
export const HIDE_PHOTO_DEPICTED_IN_OPTION = true;
export const FILTER_FOR_OTHER_PEOPLE = true;
export const REACTIONS = true;
export const GATHERING_PALETTE_PICKER = true;
export const PUBLIC_ALBUM_VIEWABLE = true;
export const STICKER_SELECTION = true;
export const GATHERING_RECAP = true;
export const ADDONS = true;

// Dogfooding flags for internal use

export const EDIT_THEME_MENU = true && curEnv !== "prod";
/*
 * If you change this flag once this is GA, make sure you enable non-employee access in the backend
 */
export const PUBLIC_ALBUM_CONTROLS_AVAILABLE = PUBLIC_ALBUM_VIEWABLE && true && curEnv !== "prod";

// Flags we won't be enabling for a while
export const SHOWCASE_ENABLED = false && notProd;
export const SCHOOL_EMAIL_VERIFICATION = false && notProd;
export const PHOTO_DETAIL_RECAP = false && notProd;
export const SHARED_ELEMENT = false && isDev;

export const WIPE_IMAGE_CACHE_ON_START = false && isDev;
export const DISABLE_PERSIST_APOLLO_CACHE = false && isDev;
export const DISABLE_NEXTJS_SERVER_CACHE = false && isDev;
export const FORCE_FILTER_LOADING = false && isDev;
export const DISABLE_LOG = false && isDev;
export const SANDBOX = false && isDev;
export const MUTUALS = false;
export const STORYBOOK = false && isDev;
export const TAG_BOUNDING_BOX = true && isDev;

/**
 * Simulates the API randomly breaking
 */
export const API_RANDOM_BREAKS = false && notProd;
export const UPLOAD_RANDOM_BREAKS = false && notProd;
